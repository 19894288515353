import {
  Box,
  Button,
  Icon,
  useDisclosure,
  useToast,
  Text,
  List,
  ListItem,
  Badge,
  HStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAttachmentLine } from 'react-icons/ri'
import { toastError } from '../../../../../config/error/toastError'
import {
  FreightOccurrencesModel,
  ICreateFreightOccurrences,
  useCreateFreightOccurrences,
  useGetAllFreightOccurrences,
} from '../../../../../services/endpoints/freights/freight-occurrences'
import { FreightType } from '../../../../../services/types'
import CreateFreightOccurrencesModal from './modals/CreateFreightOccurrencesModal'
import { FreightOccurrecesTypeEnum } from '../../../../../services/types/EnumTypes'

export const freightOccurrencesType = [
  { label: 'Sinistro', value: 'sinister' },
  { label: 'Atraso', value: 'delay' },
  { label: 'Avaria', value: 'damage' },
  { label: 'Outro', value: 'other' },
]

interface FreightOccurrencesCardProps {
  freight: FreightType
  bg: string
}

const createFreightOccurrencesFormSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
})

const FreightOccurrenceList = ({ freightOccurrences }: { freightOccurrences: FreightOccurrencesModel[] }) => {
  const getTypeLabel = (type: FreightOccurrecesTypeEnum) => {
    switch (type) {
      case FreightOccurrecesTypeEnum.SINISTER:
        return { label: 'Sinistro', color: 'red' }
      case FreightOccurrecesTypeEnum.DELAY:
        return { label: 'Atraso', color: 'yellow' }
      case FreightOccurrecesTypeEnum.DAMAGE:
        return { label: 'Dano', color: 'blue' }
      case FreightOccurrecesTypeEnum.OTHER:
        return { label: 'Outro', color: 'gray' }
      default:
        return { label: 'Desconhecido', color: 'gray' }
    }
  }

  return (
    <List spacing={3} mb={4}>
      {freightOccurrences.map(occurrence => {
        const { label, color } = getTypeLabel(occurrence.type as FreightOccurrecesTypeEnum)

        return (
          <ListItem key={occurrence.id} p={3} borderWidth="1px" borderRadius="md">
            <HStack spacing={3}>
              <Badge colorScheme={color}>{label}</Badge>
              <Text>{occurrence.description}</Text>
            </HStack>
          </ListItem>
        )
      })}
    </List>
  )
}

export default function FreightOccurrencesCard({ freight, bg }: FreightOccurrencesCardProps): JSX.Element {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: createFreightOccurrences } = useCreateFreightOccurrences({
    onSuccess: () => {
      toast({
        title: 'Ocorrência de Frete Criada com sucesso!',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
      onClose()
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const { data: freightOccurrences, isLoading } = useGetAllFreightOccurrences({
    freight_id: freight.id,
  })

  const { handleSubmit, setValue, formState } = useForm<ICreateFreightOccurrences>({
    resolver: yupResolver(createFreightOccurrencesFormSchema),
  })
  const { errors } = formState

  const handleCreateOccurrence: SubmitHandler<ICreateFreightOccurrences> = data => {
    if (freight) {
      const { id: freight_id } = freight

      createFreightOccurrences({
        freight_id,
        type: data.type,
        description: data.description,
      })
    }
  }

  return (
    <Box p={4} bg={bg} borderRadius="md" shadow="md">
      <Text fontSize="xl" mb={4}>
        Ocorrências de Frete
      </Text>

      {freightOccurrences && <FreightOccurrenceList freightOccurrences={freightOccurrences} />}

      <Button onClick={onOpen} colorScheme="orange" leftIcon={<Icon as={RiAttachmentLine} />}>
        Adicionar Ocorrência
      </Button>
      <CreateFreightOccurrencesModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSubmit}
        setValue={setValue}
        errors={errors}
        isLoading={isLoading}
        handleCreateOccurrence={handleCreateOccurrence}
      />
    </Box>
  )
}
