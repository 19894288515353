import { Box, Button, Heading, Text } from '@chakra-ui/react'
import React from 'react'

interface FormActionsProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  isLoading: boolean
  tollValue: number | undefined
  routePointsData: any
}

export const FormActions = ({
  onSubmit,
  isLoading,
  tollValue,
  routePointsData,
}: FormActionsProps): JSX.Element => {
  return (
    <>
      <form onSubmit={onSubmit}>
        <Box px={4} justify="space-between" align="center" gap="4" my={5}>
          <Button type="submit" w="100%" size="lg" colorScheme="green" isLoading={isLoading}>
            Consultar rotas disponíveis
          </Button>
        </Box>
        <Box px={4} justify="space-between" align="center" gap="4" my={5}>
          <Heading size="sm" fontWeight="bold" colorScheme="gray.500" flex={1}>
            Valor do Pedágio
          </Heading>
          <Text>
            {tollValue !== undefined
              ? `R$ ${tollValue}`
              : routePointsData && routePointsData.length > 0 && routePointsData[0].toll_value !== undefined
              ? `R$ ${routePointsData[0].toll_value}`
              : 'Sem pedágio'}
          </Text>
        </Box>
      </form>
    </>
  )
}
