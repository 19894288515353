/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spacer,
  useColorMode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useReducer, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { ToggleColorMode } from '../../../../components/ToggleColorMode'
import { toastError } from '../../../../config/error/toastError'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { Logo } from '../../../../layout/Header/Logo'
import { useGetFreightByNumber } from '../../../../services/endpoints/freights/getFreightByNumber'
import { QualpRoute, usePostPlanRoute } from '../../../../services/endpoints/qualp/postPlanRoute'
import { useGetRoutePoints } from '../../../../services/endpoints/truckpad/find-route-points'
import { getAnttValue } from '../../../../services/endpoints/truckpad/getAnttValue'
import { AnttForm } from './components/AnttForm'
import { CargoTypeSelect } from './components/CargoTypeSelect'
import { FormActions } from './components/FormActions'
import { LocationManager, QualpCityType } from './components/LocationManager'
import { RouteConfigSwitches } from './components/RouteConfigSwitches'
import { SelectComponent } from './components/SelectComponent'
import { TruckTypeSelect } from './components/TruckTypeSelect'
import { VehicleCategorySelect } from './components/VehicleCategorySelect'
import { RoutePlannerModalQualp } from './modals/RoutePlannerModalQualp'
import { initialPlanRouteState, routePlannerReducer } from './reducer'
import RoutePlannerMapComponentQualp from './RoutePlannerMapComponentQualp'

export type PlaceType = {
  id?: string
  position: google.maps.LatLngLiteral & { label?: string }
  cityName?: string
  type?: string
}

interface RoutePlannerMapProps {
  freight_id?: string
}

const routePlannerFormSchema = yup.object().shape({
  vehicleCategory: yup.string().required('Selecione a categoria do veículo'),
  axesNumber: yup.mixed().required('Número de eixos é obrigatório'),
  cargoType: yup.string(),
  origin: yup.mixed().required('Selecione a origem'),
  destination: yup.mixed().required('Selecione o destino'),
  truckCategory: yup.mixed().when('vehicleCategory', {
    is: 'truck',
    then: yup.mixed().required('Selecione o tipo de caminhão'),
  }),
})

export default function RoutePlannerMap({ freight_id }: RoutePlannerMapProps): JSX.Element {
  const toast = useToast()
  const history = useHistory()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [state, dispatch] = useReducer(routePlannerReducer, initialPlanRouteState)
  const { data: routePointsData } = useGetRoutePoints({ freight_id: String(freight_id) })
  const freightNumberQueryParam = useQueryParams('freight_number')
  const originParam = useQueryParams('origin')
  const destinationParam = useQueryParams('destination')
  const isPageFreight = useQueryParams('isFreightPage')
  const isPendenciesPage = useQueryParams('isPendenciesPage')

  const { data: freightData } = useGetFreightByNumber(freightNumberQueryParam)
  const [resultQualp, setResultQualp] = useState<any | undefined>()
  const { colorMode } = useColorMode()

  const {
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(routePlannerFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (originParam) {
      dispatch({ type: 'SET_ORIGIN', payload: JSON.parse(originParam) })
    }
    if (destinationParam) {
      dispatch({ type: 'SET_DESTINATION', payload: JSON.parse(destinationParam) })
    }
    if (state.shouldUpdateUrl) {
      const params = new URLSearchParams()
      params.set('places', JSON.stringify(state.places))
      params.set('cargoType', state.cargoType)
      params.set('axesNumber', String(state.axesNumber))
      window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`)
      dispatch({ type: 'SET_SHOULD_UPDATE_URL', payload: false })
    }
  }, [state.shouldUpdateUrl, state.places, state.cargoType, state.axesNumber, originParam, destinationParam])

  const {
    mutateAsync: mutateAsyncCreate,
    isLoading,
    error,
  } = usePostPlanRoute({
    onSuccess: async response => {
      setResultQualp(response)

      toast({
        title: 'Requisição feita com sucesso!',
        description: 'Dados do pedágio consultados com sucesso.',
        status: 'success',
        duration: 9000,
        position: 'top',
        isClosable: true,
      })
      dispatch({ type: 'SET_ROUTES', payload: response.data })
      onOpen()
    },
    onError: () => {
      toastError({ toast, error })
    },
  })

  useEffect(() => {
    const body = document.querySelector('body')

    if (body) {
      body.style.overflow = 'hidden'
    }

    return () => {
      if (body) {
        body.style.overflow = 'auto'
      }
    }
  }, [])

  return (
    <Flex h="100vh" overflow="hidden">
      <Box w="750px" p={0} h="100%" background={colorMode === 'light' ? 'gray.100' : 'gray.800'}>
        <Flex alignItems="center" justifyContent="center">
          <Heading p={4} size="lg">
            <Logo />
          </Heading>
          <ToggleColorMode />
        </Flex>
        <Spacer marginY={4} />

        <Box p={2} overflow="auto" h="calc(100vh - 100px)" className="scrollable-content">
          {freight_id && (
            <Box px="6">
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => {
                  history.goBack()
                  // if (isPageFreight) {
                  //   history.goBack()
                  // } else if (isPendenciesPage) {
                  //   // caso onde o usuário está na página de pendências, mas vem pelo botão que fica abaixo do titulo
                  //   const oldUrl = localStorage.getItem('currentUrl')
                  //   if (!oldUrl) return
                  //   const url = new URL(oldUrl)
                  //   const currentTabIndex = url.searchParams.get('tabIndex')
                  //   const newTabIndex = Number(currentTabIndex) + 1

                  //   url.searchParams.set('tabIndex', newTabIndex.toString())

                  //   localStorage.setItem('currentUrl', url.toString())
                  //   window.location.href = url.toString()
                  // } else {
                  //   // caso onde o usuário vem pela tela após antt
                  //   const oldUrl = localStorage.getItem('currentUrl')
                  //   if (!oldUrl) return
                  //   const url = new URL(oldUrl)
                  //   const currentTabIndex = url.searchParams.get('tabIndex')
                  //   const newTabIndex = Number(currentTabIndex) + 1
                  //   url.searchParams.set('tabIndex', newTabIndex.toString())

                  //   localStorage.setItem('currentUrl', url.toString())
                  //   window.location.href = url.toString()
                  // }
                }}
              >
                {isPageFreight ? 'Voltar para a página do frete' : 'Voltar para a página de pendências'}
              </Button>
            </Box>
          )}
          <Box p="6">
            <Heading size="sm" fontWeight="bold" mb="4">
              Roteirizador{' '}
              {freightData?.freight_number && (
                <>
                  - <Link to={`/freights/show/${freightData?.id}`}>Frete #{freightData?.freight_number}</Link>
                </>
              )}
            </Heading>

            <Divider />

            <Flex direction="column" gridGap="6" pt="6">
              <VehicleCategorySelect
                vehicleCategoryCode={state.vehicleCategoryCode}
                setVehicleCategoryCode={value => {
                  dispatch({ type: 'SET_VEHICLE_CATEGORY_CODE', payload: value })
                }}
                setValue={setValue}
                errors={errors.vehicleCategory}
              />

              {state.vehicleCategoryCode === 'truck' && (
                <TruckTypeSelect
                  value={state.truckCategory}
                  setValue={setValue}
                  setTruckCategory={event =>
                    dispatch({ type: 'SET_TRUCK_CATEGORY', payload: Number(event.target.value) })
                  }
                  errors={errors.truckCategory}
                />
              )}

              <SelectComponent
                label="Número de Eixos"
                value={state.axesNumber}
                onChange={e => dispatch({ type: 'SET_AXES_NUMBER', payload: Number(e.target.value) })}
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(eixo => ({
                  label: `${eixo} Eixos`,
                  value: String(eixo),
                }))}
                error={errors.axesNumber}
                setValue={setValue}
              />

              <CargoTypeSelect
                cargoType={state.cargoType}
                setCargoType={value => dispatch({ type: 'SET_CARGO_TYPE', payload: value })}
                errorsAntt={errors.cargoType}
                setValue={setValue}
              />

              <RouteConfigSwitches
                isOptimizedRoute={state.isOptimizedRoute}
                setIsOptimizedRoute={value => dispatch({ type: 'SET_IS_OPTIMIZED_ROUTE', payload: value })}
                alternativeRoutes={state.alternativeRoutes}
                setAlternativeRoutes={value =>
                  dispatch({ type: 'SET_ALTERNATIVE_ROUTES', payload: String(value) })
                }
                typeRoute={state.typeRoute}
                setTypeRoute={value => dispatch({ type: 'SET_TYPE_ROUTE', payload: value })}
                setValue={setValue}
                errors={errors}
              />

              <LocationManager
                origin={
                  originParam
                    ? {
                        cityName: JSON.parse(originParam).cityName,
                        lat: JSON.parse(originParam).lat,
                        lng: JSON.parse(originParam).lng,
                      }
                    : state.origin
                }
                setOrigin={value => dispatch({ type: 'SET_ORIGIN', payload: value })}
                destination={
                  destinationParam
                    ? {
                        cityName: JSON.parse(destinationParam).cityName,
                        lat: JSON.parse(destinationParam).lat,
                        lng: JSON.parse(destinationParam).lng,
                      }
                    : state.destination
                }
                setDestination={value => dispatch({ type: 'SET_DESTINATION', payload: value })}
                places={state.places}
                setPlaces={value => dispatch({ type: 'SET_PLACES', payload: value })}
                errors={errors}
                setValue={setValue}
                isBlockedEntity={state.isBlockedEntity}
              />
            </Flex>
          </Box>

          <FormActions
            onSubmit={handleSubmit(async () => {
              await mutateAsyncCreate({
                optimized_route: state.isOptimizedRoute,
                optimized_route_destination: state.optimizedRouteDestination,
                calculate_return: state.calculateReturn,
                alternative_routes: state.alternativeRoutes,
                avoid_locations: state.avoidLocations,
                avoid_locations_key: state.avoidLocationsKey,
                type_route: state.typeRoute,
                vehicle_category: state.vehicleCategoryCode,
                axes_number: String(state.axesNumber),
                cargo_type: state.cargoType,
                origin: state.origin as QualpCityType,
                destination: state.destination as QualpCityType,
                steps: state.places,
              })
            })}
            isLoading={isLoading}
            tollValue={state.tollValue}
            routePointsData={routePointsData}
          />

          <Divider my={6} />

          {!freight_id && (
            <Box overflow="hidden">
              <AnttForm
                distance={state.distance}
                setDistance={value => dispatch({ type: 'SET_DISTANCE', payload: value })}
                axesNumber={state.axesNumber}
                setAxesNumber={value => dispatch({ type: 'SET_AXES_NUMBER', payload: value })}
                cargoType={state.cargoType}
                setCargoType={value => dispatch({ type: 'SET_CARGO_TYPE', payload: value })}
                handleAnttValueConsultation={() =>
                  getAnttValue({
                    cargoType: state.cargoType,
                    axesNumber: String(state.axesNumber),
                    distance: String(state.distance),
                    isVehicleComposition: String(state.isVehicleComposition),
                    isHighPerformance: String(state.isHighPerformance),
                    isReturnEmpty: String(state.isReturnEmpty),
                  })
                    .then(response => {
                      dispatch({ type: 'SET_ANTT_VALUE', payload: response.value })
                      toast({
                        title: 'Requisição feita com sucesso!',
                        description: 'Valor ANTT consultado com sucesso.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                      })
                    })
                    .catch(err => {
                      toast({
                        title: 'Erro ao consultar valor ANTT',
                        description: `Não foi possível consultar o valor ANTT: ${err.message}`,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                        position: 'top',
                      })
                    })
                    .finally(() => {
                      dispatch({ type: 'SET_IS_LOADING_ANTT_VALUE', payload: false })
                    })
                }
                isLoadingAnttValue={state.isLoadingAnttValue}
                anttValue={state.anttValue}
              />
            </Box>
          )}
        </Box>
      </Box>

      {!isOpen && (
        <Box w="100%" h="100vh">
          <RoutePlannerMapComponentQualp
            origin={state.origin as QualpCityType}
            destination={state.destination as QualpCityType}
            stopPlaces={state.places?.length ? state.places : []}
            places={routePointsData?.map((point: any) => ({
              lat: point.latitude,
              lng: point.longitude,
            }))}
            zoom={7}
          />
        </Box>
      )}

      <Box w={['100%', '100%', isOpen ? '30vw' : '0px']} p={0} h="100vh">
        <RoutePlannerModalQualp
          isOpen={isOpen}
          onClose={onClose}
          routes={resultQualp?.routes as QualpRoute[]}
          origin={state.origin}
          destination={state.destination}
          truckCategory={state.truckCategory}
          freight_id={freight_id}
        />
      </Box>
    </Flex>
  )
}
