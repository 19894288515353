import { UseQueryResult, useQuery } from 'react-query'
import { apiServer } from '../../../api'
import { FreightOccurrecesTypeEnum } from '../../../types/EnumTypes'
import { ResArrayApiType } from '../../../types/ResponseApiType'

interface IGetAllFreightOccurrencesParams {
  freight_id?: string
  type?: FreightOccurrecesTypeEnum
}

export type FreightOccurrencesModel = {
  id: string
  freight_id: string
  type: FreightOccurrecesTypeEnum
  description: string
}

export async function fetchAllFreightOccurrences(
  params: IGetAllFreightOccurrencesParams,
): Promise<FreightOccurrencesModel[]> {
  const { data: response } = await apiServer.get<ResArrayApiType<FreightOccurrencesModel>>(
    '/freight-occurrences',
    {
      params: {
        ...params,
      },
    },
  )
  const { data } = response

  return data
}

export function useGetAllFreightOccurrences(
  params: IGetAllFreightOccurrencesParams,
): UseQueryResult<FreightOccurrencesModel[]> {
  return useQuery(['all-freights', params], () => fetchAllFreightOccurrences(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
