/* eslint-disable react-hooks/rules-of-hooks */
import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { getNextStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/NextStatusQuotationButton'
import { apiServer } from '../../api'
import { FreightType, ResponseApiType } from '../../types'
import { IMonitoringOccurences } from '../../types/MonitoringOccurenceType'
import FreightExpenseType from '../../types/FreightExpenseType'
import { formattedTransporter } from '../../utils/formattedTransporter'
import parseCurrency from '../../utils/parseCurrency'
import { formatCte } from '../datamex/getCtes'
import {
  colorStatus,
  formatEvent,
  formatStatusFreightExpense,
  FormattedFreightExpenseType,
} from './financial/getAllFinancialTitles'
import { Coords, getNextStatus, getPriceType, getStatus, getType } from './getFreights'
import { FreightRelationsType } from '../../types/FreightType'

export interface FormattedOneFreight extends FreightType {
  formatted_toll_ticket: string
  formatted_type: string
  formatted_status: string
  formatted_received_at: string
  formatted_link_motorist_at?: string
  input_collect_cargo_at?: string
  formatted_delivered_cargo_at?: string
  formatted_origin_coords?: Coords
  formatted_destination_coords?: Coords
  next_status: SelectOption | null
  next_status_quotation?: SelectOption
  previous_status_quotation?: SelectOption
  origin_city: string
  destination_city: string
  formatted_suggested_price: string
  formatted_agreed_price: string
  formatted_suggested_price_type: string
  formatted_vehicle: string
  formatted_round_trip: string
  formatted_has_toll: string
  created_at_hour: string
  cargo_value: string
  cargo_dimensions: string
  cargo_weight: string
  is_cargo_complement: string
  cargo_require_tracker: string
  can_monitoring_whatsapp?: boolean
  created_at_default: string | Date
  formatted_updated_at: string
  whatsapp_notification_interval: number
  whatsapp_next_notification_date: string
  monitoring_occurrences: IMonitoringOccurences[]
  formatted_freight_expenses: FormattedFreightExpenseType[] | null
  formatted_ctes: any[] | null
}

type Input = {
  freight_id: string
  relations?: FreightRelationsType[]
}

const formatExpensive = (freight_expenses?: FreightExpenseType[] | null): FormattedFreightExpenseType[] => {
  if (!freight_expenses) return []
  return freight_expenses.map(expense => {
    return {
      ...expense,
      created_at_formatted: format(new Date(expense.created_at), 'dd/MM/yy - HH:mm'),
      updated_at_formatted: format(new Date(expense.updated_at), 'dd/MM/yy - HH:mm'),
      receipt_at: expense.receipt_at ? format(new Date(expense.receipt_at), "dd/MM/yy 'às' HH:mm") : '',
      payment_at_formatted: expense.payment_at
        ? format(new Date(expense.payment_at), "dd/MM/yyyy 'às' HH:mm")
        : '',
      transporter: formattedTransporter(expense.transporter),
      event: formatEvent(expense.event),
      status: formatStatusFreightExpense(expense.status),
      paid: expense.paid ? 'Sim' : 'Não',
      valueFormatted: parseCurrency(expense.value),
      color_status: colorStatus(expense.status),
    }
  })
}

export async function fetchOneFreight({
  freight_id,
  relations,
}: Input): Promise<FormattedOneFreight | undefined> {
  if (freight_id && freight_id !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<FormattedOneFreight>>(
      `find-freight/${freight_id}`,
      { params: { relations } },
    )

    const { data } = response

    const createdAt = new Date(data.created_at)
    const updatedAt = new Date(data.updated_at)
    const receivedAt = data.received_at ? new Date(data.received_at) : new Date()
    const linkMotoristAt = data.link_motorist_at ? new Date(data.link_motorist_at) : undefined
    const collectCargoAt = data.collect_cargo_at ? new Date(data.collect_cargo_at) : undefined
    const deliveredCargoAt = data.delivered_cargo_at ? new Date(data.delivered_cargo_at) : undefined

    const freight = {
      ...data,
      formatted_freight_expenses: formatExpensive(data.freight_expenses),
      formatted_ctes: formatCte(data.ctes),
      formatted_toll_ticket: String(data.toll_ticket),
      formatted_type: getType(data.type),
      formatted_status: getStatus(data.status),
      formatted_received_at: format(receivedAt, "yyyy-MM-dd'T'HH:mm:ss"),
      formatted_link_motorist_at: linkMotoristAt
        ? format(linkMotoristAt, "yyyy-MM-dd'T'HH:mm:ss")
        : undefined,
      input_collect_cargo_at: collectCargoAt ? format(collectCargoAt, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
      formatted_delivered_cargo_at: deliveredCargoAt
        ? format(deliveredCargoAt, "yyyy-MM-dd'T'HH:mm:ss")
        : undefined,
      next_status: getNextStatus(data.status),
      next_status_quotation: getNextStatusQuotation(data.status),
      origin_city: data.origin ? `${data.origin?.name} - ${data.origin?.state?.uf}` : '',
      destination_city: data.destination ? `${data.destination?.name} - ${data.destination?.state?.uf}` : '',
      formatted_suggested_price: parseCurrency(data.suggested_price),
      formatted_agreed_price: parseCurrency(data.agreed_price),
      formatted_suggested_price_type: getPriceType(data.suggested_price_type),
      formatted_vehicle:
        data.vehicle?.vehicle_category && data.vehicle?.vehicle_body
          ? `${data.vehicle?.vehicle_category?.name} / ${data.vehicle?.vehicle_body?.name}`
          : '',
      formatted_has_toll: data.has_toll ? 'Sim' : 'Não',
      created_at: format(createdAt, 'dd/MM'),
      created_at_hour: format(createdAt, 'HH:mm'),
      cargo_value: data.cargo?.value ? parseCurrency(data.cargo.value) : '',
      cargo_dimensions: data.cargo
        ? `${data.cargo?.width} x ${data.cargo?.height} x ${data.cargo?.depth}`
        : '',
      cargo_weight: data.cargo?.weight ? `${data.cargo?.weight} kg` : '',
      is_cargo_complement: data.cargo?.has_complement ? 'Sim' : 'Não',
      cargo_require_tracker: data.cargo?.require_tracker ? 'Sim' : 'Não',
      formatted_origin_coords: data?.origin_coords
        ? {
            lat: String(data?.origin_coords).split(',')[0],
            lng: String(data?.origin_coords).split(',')[1],
          }
        : undefined,
      formatted_destination_coords: data?.destination_coords
        ? {
            lat: String(data?.destination_coords).split(',')[0],
            lng: String(data?.destination_coords).split(',')[1],
          }
        : undefined,
      created_at_default: data.created_at,
      formatted_updated_at: format(updatedAt, "dd/MM 'às' HH:mm"),
      whatsapp_notification_interval: data.whatsapp_notification_interval,
      whatsapp_next_notification_date: data.whatsapp_next_notification_date,
    }

    return freight
  }
  return undefined
}

export function useGetOneFreight(input: Input): UseQueryResult<FormattedOneFreight> {
  return useQuery(['freight', input], () => fetchOneFreight(input), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
