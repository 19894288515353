import { apiServer } from '../../api'
import { ClientBusinessGroup, ResponseApiType } from '../../types'
import ClientBusinessGroupSegment from '../../types/ClientBusinessGroupSegment'

type Response = {
  label: string
  value: string
}

export async function getClientBusinessGroupSegment(name?: string): Promise<Response[]> {
  const { data } = await apiServer.get<ResponseApiType<ClientBusinessGroup[]>>(
    '/clients/client-business-group/segments',
    {
      params: {
        name,
      },
    },
  )

  return data.data.map(segment => {
    return {
      label: segment.name,
      value: segment.id,
    }
  })
}

export async function getClientBusinessGroup(name?: string): Promise<Response[]> {
  const { data } = await apiServer.get<ResponseApiType<{ data: ClientBusinessGroupSegment[] }>>(
    '/clients/client-business-group',
    {
      params: {
        name,
        unpaged: true,
      },
    },
  )

  return data.data.data.map(group => {
    return {
      label: group.name,
      value: group.id,
    }
  })
}
