import {
  Alert,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import { MdAdd } from 'react-icons/md'
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri'
import { Ability } from '../../components/ability'
import { AlertDialogConfirm } from '../../components/AlertDialogConfirm'
import TextWithLimit from '../../components/TextWithLimit'
import { queryClient } from '../../config/react-query'
import { useAbility } from '../../hooks/useAbility'
import { FormattedOneFreight } from '../../services/endpoints/freights'
import { useDeleteTrackerDevice } from '../../services/endpoints/tracker-devices/deleteTrackerDevices'
import {
  FiltersTrackerDevices,
  ReturnFormattedTrackerDevices,
  useGetTrackerDevices,
} from '../../services/endpoints/tracker-devices/getTrackerDevices'
import { copyToClipboard } from '../../services/utils/copyToClipboard'
import { ChangeStatusModal } from './modals/ChangeStatusModal'
import { CreateOrEditModal } from './modals/CreateOrEditModal'

export function ListTrackerDevices({
  freight,
  bg,
}: {
  freight: FormattedOneFreight
  bg: string
}): JSX.Element {
  const toast = useToast()
  const abilityUpdateTrackerDevice = useAbility('operation', 'update-tracker-devices')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()
  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure()
  const [trackerDeviceSelected, setTrackerDeviceSelected] = useState<ReturnFormattedTrackerDevices>()
  const { data: trackerDevices, isLoading } = useGetTrackerDevices({
    freight_id: freight.id,
    vehicle_id: freight.vehicle_id,
  } as FiltersTrackerDevices)
  const deleteTrackerDevices = useDeleteTrackerDevice({
    onSuccess: () => {
      queryClient.invalidateQueries('list-tracker-devices')
    },
  })

  return (
    <>
      <Box bg={bg} p="4" borderRadius="8" shadow="md" mt="4">
        <Flex justify="space-between" align="center" direction={['column', 'column', 'row']} gridGap={4}>
          <Heading fontSize="md" fontWeight="medium">
            Dispositivos
          </Heading>
          <Button
            size="sm"
            colorScheme="orange"
            onClick={onOpen}
            variant="outline"
            leftIcon={<Icon as={MdAdd} />}
          >
            + Adicionar dispositivo
          </Button>
        </Flex>
        <Divider my="6" />

        {isLoading ? (
          <>
            <Flex justifyContent="center">
              <Spinner />
            </Flex>
          </>
        ) : trackerDevices && trackerDevices.data.length > 0 ? (
          <TableContainer maxW="1100px">
            {!freight.vehicle_id && (
              <Alert status="info" mb={4}>
                <AlertIcon />
                <AlertTitle fontSize="md">
                  As opções de rastreadores só ficarão disponíveis após o veículo ser vinculado!
                </AlertTitle>
              </Alert>
            )}

            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Tipo</Th>
                  <Th>Marca</Th>
                  <Th>Obs.</Th>
                  <Th>Atualizado</Th>
                  <Th>Resp.</Th>
                  <Th>Link</Th>
                  <Th>Usuário</Th>
                  <Th>Senha</Th>
                  <Th>Status</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody flex={1}>
                {trackerDevices.data.map((data: ReturnFormattedTrackerDevices) => (
                  <>
                    <Tr key={data.id}>
                      <Td fontSize="xs">{data.type_formatted}</Td>
                      <Td fontSize="xs">
                        <Text>{data.brand_formatted}</Text>
                      </Td>
                      <Td fontSize="xs">
                        <TextWithLimit text={data.note || ''} limit={15} />
                      </Td>
                      <Td fontSize="xs">
                        <Text>{data.updated_at_formatted}</Text>
                      </Td>
                      <Td fontSize="xs">
                        <Text>{data.creator?.name}</Text>
                      </Td>
                      <Td fontSize="xs">
                        <Link href={`${data.link}`} target="_blank" rel="noreferrer">
                          <Text>Acessar</Text>
                        </Link>
                      </Td>
                      <Td fontSize="xs">
                        {data.login ? (
                          <Tooltip hasArrow label="Clique aqui para copiar">
                            <Button
                              colorScheme="green"
                              onClick={() => {
                                copyToClipboard(data.login)
                                toast({
                                  title: 'Copiado com sucesso!',
                                  status: 'success',
                                  position: 'top-right',
                                  duration: 1000,
                                })
                              }}
                              size="xs"
                            >
                              <AiOutlineCopy size={18} />
                            </Button>
                          </Tooltip>
                        ) : (
                          'Não informado'
                        )}
                      </Td>
                      <Td fontSize="xs">
                        {data.password ? (
                          <Tooltip hasArrow label="Clique aqui para copiar">
                            <Button
                              colorScheme="green"
                              onClick={() => {
                                copyToClipboard(data.password)
                                toast({
                                  title: 'Copiado com sucesso!',
                                  status: 'success',
                                  position: 'top-right',
                                  duration: 1000,
                                })
                              }}
                              size="xs"
                            >
                              <AiOutlineCopy size={18} />
                            </Button>
                          </Tooltip>
                        ) : (
                          'Não informada'
                        )}
                      </Td>
                      <Td fontSize="xs">
                        {abilityUpdateTrackerDevice ? (
                          <Tooltip placement="top" hasArrow label="Clique para alterar o status">
                            <Button
                              size="xs"
                              onClick={() => {
                                onOpenChangeStatus()
                                setTrackerDeviceSelected(data)
                              }}
                              colorScheme={data.is_tracker_verified ? 'green' : 'red'}
                            >
                              {data.is_tracker_verified ? 'VERIFICADO' : 'NÃO VERIFICADO'}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Badge rounded="5" p="1" colorScheme={data.is_tracker_verified ? 'green' : 'red'}>
                            {data.is_tracker_verified ? 'VERIFICADO' : 'NÃO VERIFICADO'}
                          </Badge>
                        )}
                      </Td>
                      <Td fontSize="xs">
                        <HStack>
                          <Tooltip hasArrow label="Editar dispositivo">
                            <Button
                              onClick={() => {
                                onOpen()
                                setTrackerDeviceSelected(data)
                              }}
                              size="xs"
                              colorScheme="orange"
                            >
                              <RiEdit2Line size={18} />
                            </Button>
                          </Tooltip>
                          <Ability module="spotHub" action="show-delete-tracker-device">
                            <Tooltip hasArrow label="Apagar dispositivo">
                              <Box>
                                <Button
                                  cursor="pointer"
                                  colorScheme="red"
                                  onClick={() => {
                                    onOpenConfirm()
                                    setTrackerDeviceSelected(data)
                                  }}
                                  size="xs"
                                >
                                  <RiDeleteBin6Line size={18} />
                                </Button>
                              </Box>
                            </Tooltip>
                          </Ability>
                        </HStack>
                      </Td>
                    </Tr>
                  </>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Flex justifyContent="center">
            <Text>Não há dispositivos cadastrados</Text>
          </Flex>
        )}

        <CreateOrEditModal
          freight={freight}
          isOpen={isOpen}
          tracker={trackerDeviceSelected}
          onClose={() => {
            onClose()
            setTrackerDeviceSelected(undefined)
          }}
        />
        {trackerDeviceSelected && (
          <>
            <ChangeStatusModal
              freight_id={freight.id}
              tracker={trackerDeviceSelected}
              isOpen={isOpenChangeStatus}
              onClose={() => {
                onCloseChangeStatus()
                setTrackerDeviceSelected(undefined)
              }}
            />
            <AlertDialogConfirm
              isOpen={isOpenConfirm}
              title="Remover Dispositivo"
              description="Tem certeza que deseja remover este dispositivo?"
              onClose={onCloseConfirm}
              onConfirm={async () => {
                deleteTrackerDevices.mutateAsync(trackerDeviceSelected.id)
              }}
            />
          </>
        )}
      </Box>
      {/* <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8" mb="8">
        <Flex justify="space-between" align="center" direction={['column', 'column', 'row']} gridGap={4}>
          <Heading fontSize={['lg', 'x-large', 'xx-large']} fontWeight="normal">
            Monitoramento Manual
          </Heading>
        </Flex>
        <Divider my={6} />
        <PopoverWaypoints freight={freight} freight_distance_total={freight.distance} />
      </Box> */}
    </>
  )
}
