import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

export type RoutePoint = {
  latitude: number
  longitude: number
}
export type ParamsRoutePoints = {
  points: RoutePoint[]
  freight_id: string
  toll_value: number
  provider?: string
  freight_distance?: number
}

async function postRoutePoints(data: ParamsRoutePoints): Promise<AxiosResponse> {
  return apiServer.post('/create-route-points', data)
}

export function usePostRoutePoints(
  options?: UseMutationOptions<AxiosResponse, unknown, ParamsRoutePoints, unknown>,
): UseMutationResult<AxiosResponse, unknown, ParamsRoutePoints, unknown> {
  return useMutation(async (data: ParamsRoutePoints) => postRoutePoints(data), options)
}
