import { Box, Heading } from '@chakra-ui/react'
import { Select } from '../../../../../components/form'

interface CargoTypeSelectProps {
  cargoType: string
  setCargoType: (value: string) => void
  errorsAntt: any
  setValue: any
}
export const CargoTypeSelect = ({
  cargoType,
  setCargoType,
  errorsAntt,
  setValue,
}: CargoTypeSelectProps): JSX.Element => {
  return (
    <Box py="4">
      <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
        Tipo de Carga
      </Heading>
      <Select
        isRequired
        placeholder="Selecione o tipo de carga"
        value={cargoType}
        onChange={e => setCargoType(e.target.value)}
        error={errorsAntt?.cargoType}
        name="cargoType"
        setValue={setValue}
        options={[
          { value: '5', label: 'Carga Geral' },
          { value: '12', label: 'Carga Granel Pressurizada' },
          { value: '4', label: 'Conteinerizada' },
          { value: '3', label: 'Frigorificada ou Aquecida' },
          { value: '2', label: 'Granel Líquido' },
          { value: '1', label: 'Granel Sólido' },
          { value: '6', label: 'Neogranel' },
          { value: '11', label: 'Perigosa (Carga Geral)' },
          { value: '10', label: 'Perigosa (Conteinerizada)' },
          { value: '9', label: 'Perigosa (Frigorificada ou Aquecida)' },
          { value: '8', label: 'Perigosa (Granel Líquido)' },
          { value: '7', label: 'Perigosa (Granel Sólido)' },
        ]}
      />
    </Box>
  )
}
