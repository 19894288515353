import { Box, Heading } from '@chakra-ui/react'
import { Select } from '../../../../../components/form'

interface SelectComponentProps {
  label: string
  value: number
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: { value: string; label: string }[]
  error: any
  setValue: any
}

export const SelectComponent = ({
  label,
  value,
  onChange,
  options,
  error,
  setValue,
}: SelectComponentProps): JSX.Element => {
  return (
    <Box py="4">
      <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
        {label}
      </Heading>
      <Select
        value={value}
        initialValue={value.toString()}
        onChange={onChange}
        placeholder={`Selecione ${label.toLowerCase()}`}
        isRequired
        name="axesNumber"
        setValue={setValue}
        error={error}
        options={options}
      />
    </Box>
  )
}
