/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

export interface CreateClientFormData {
  cnpj: string
  name: string
  nickname: string
  main_activity: string
  email?: string
  phone?: string
  zipcode?: string
  address?: string
  address_number?: number
  district?: string
  city?: string
  state?: string
  payment_method: string
  client_business_group_id?: string
  clientBusinessGroup?: {
    name: {
      label: string
      value?: string
    }
    segment?: {
      label: string
      value?: string
    }
  }
  ddr: boolean
  pocket_insurance: string
  payment_deadline: string
  effective_insurance: string
}

interface CreateClientData {
  cnpj: string
  name: string
  nickname: string
  main_activity: string
  email?: string
  phone?: string
  zipcode?: string
  address?: string
  address_number?: number
  district?: string
  city?: string
  state?: string
  payment_method: string
  client_business_group_id?: string
  clientBusinessGroup?: {
    name: string
  }
  ddr: boolean
  pocket_insurance: string
  payment_deadline: string
  effective_insurance: string
}

export interface Cnpj {
  atividade_principal: {
    text: string
    code: string
  }[]
  nome: string
  uf: string
  telefone: string
  atividades_secundarias: {
    text: string
    code: string
  }[]
  cep: string
  bairro: string
  logradouro: string
  numero: string
  municipio: string
  porte: string
  abertura: string
  natureza_juridica: string
  fantasia: string
  cnpj: string
  status: string
  complemento: string
  email: string
}

async function createClient(data: CreateClientData) {
  const { ddr, pocket_insurance, payment_deadline, effective_insurance } = data
  if (!data.client_business_group_id) {
    const clientBusinessGroupFormatted = {
      name: data.clientBusinessGroup?.name,
      ddr,
      pocket_insurance,
      payment_deadline,
      effective_insurance,
    }
    Object.assign(data, { clientBusinessGroup: clientBusinessGroupFormatted })
  }

  return apiServer.post('/create-client', data)
}

export function usePostClient(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateClientData, unknown>,
) {
  return useMutation(async (data: CreateClientData) => createClient(data), options)
}
