/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { GiArtificialIntelligence } from 'react-icons/gi'
import { useDispatch } from 'react-redux'
import { TextArea } from '../../../components/form'
import { Autocomplete } from '../../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../../components/form/AutocompleteAsync'
import { AutocompleteMulti } from '../../../components/form/AutocompleteMulti'
import { Input } from '../../../components/form/Input'
import { InputNumberMask } from '../../../components/form/InputNumberMask'
import { RadioGroup } from '../../../components/form/RadioGroup'
import { Select } from '../../../components/form/Select'
import { Switch } from '../../../components/form/Switch'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { useAuth } from '../../../contexts/AuthContext'
import { useIsBlockEntity } from '../../../services/endpoints/blocked-entities/isBlockEntity'
import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'
import { useListClientContactsByBusinessGroup } from '../../../services/endpoints/clients/client-contacts/getContactsAutocomplete'
import { fetchClient } from '../../../services/endpoints/clients/getClient'
import { searchClientsByName } from '../../../services/endpoints/clients/searchClients'
import {
  getFreightDistance,
  PostPredictParams,
  predictServicePrice,
} from '../../../services/endpoints/freights'
import { getUserOperation } from '../../../services/endpoints/users/getUserOperation'
import { getUserSales } from '../../../services/endpoints/users/getUserSales'
import { getVehicleBodies } from '../../../services/endpoints/vehicles/getVehicleBodies'
import { getVehicleCategories } from '../../../services/endpoints/vehicles/getVehicleCategories'
import { CargoType } from '../../../services/types'
import { cargoCategoryFretebrasId } from '../../../services/types/CargoType'
import ClientType, { ClientContactType } from '../../../services/types/ClientType'
import { conveyers } from '../../../services/utils/conveyers'
import { useAppSelector } from '../../../store'
import { setClientBusinessGroupId } from '../../../store/slices/freightSlice'
import { ContactByClientForm } from '../../clients/ContactByClientForm'
import { editableFields, usersFreightEditors } from '../../freights/utils/permissions'

export interface FreightFormData {
  id?: string
  type?: string
  status?: string
  transporter?: string
  creator_id?: AutocompleteOption
  seller_id?: AutocompleteOption
  client_id?: AutocompleteOption & { client_business_group_id?: string }
  client_contact_id?: AutocompleteOption
  received_at?: string
  link_motorist_at?: string
  collect_cargo_at?: string
  delivered_cargo_at?: string
  origin_id?: AutocompleteOption
  destination_id?: AutocompleteOption
  address_origin?: AutocompleteOption
  address_destination?: AutocompleteOption
  address_collect?: string
  address_delivery?: string
  origin_complement?: string
  destination_complement?: string
  collect_complement?: string
  delivery_complement?: string
  vehicle_categories?: AutocompleteOption[]
  vehicle_bodies?: AutocompleteOption[]
  suggested_price?: number
  suggested_price_type?: string
  agreed_price?: number
  service_price?: number
  has_toll?: boolean
  description?: string
  publish_on_fretebras?: boolean
  required_items?: string
  sector?: string
  quotation_id?: string
  client_ref_type?: string
  client_ref_number?: string
  client?: Partial<ClientType>
  client_contact?: Partial<
    ClientContactType & { switch_add_contact?: boolean; client_contact_id?: AutocompleteOption }
  >
  cargo?: Partial<CargoType>
}

interface FreightFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialData?: FreightFormData
  isEdit?: boolean
}

export function FreightAndQuotationForm({
  setValue,
  formState,
  initialData,
  isEdit,
}: FreightFormProps): JSX.Element {
  const dispatch = useDispatch()
  const toast = useToast()
  const { user } = useAuth()
  const { errors } = formState
  const { freight, client_business_group_id, isQuotation } = useAppSelector(state => state.freightSlice)
  const {
    data: clienteContacts,
    isLoading,
    isFetching,
  } = useListClientContactsByBusinessGroup(client_business_group_id)
  const [vehicleCategories, setVehicleCategories] = useState<AutocompleteOption[]>()
  const [vehicleBodies, setVehicleBodies] = useState<AutocompleteOption[]>()
  const [isClearStateClientContact, setIsClearStateClientContact] = useState(false)
  const [isAddClientContact, setIsAddClientContact] = useState(false)
  const [usersOperation, setUsersOperation] = useState<AutocompleteOption[]>([])
  const [usersSales, setUsersSales] = useState<AutocompleteOption[]>([])
  const [publishOnFretebras, setPublishOnFretebras] = useState(initialData?.publish_on_fretebras)
  const [suggestedPriceType, setSuggestedPriceType] = useState('')
  const [servicePricePredictions, setServicePricePredictions] = useState<string>()
  const [inputToPredict, setInputToPredict] = useState<PostPredictParams>({
    type: undefined,
    has_toll: undefined,
    distance: undefined,
    origin_is_capital: undefined,
    destination_is_capital: undefined,
    origin_lat: undefined,
    origin_lng: undefined,
    destination_lat: undefined,
    destination_lng: undefined,
    width: undefined,
    height: undefined,
    depth: undefined,
    weight: undefined,
    value: undefined,
    require_tracker: undefined,
    has_complement: undefined,
  })
  const [isLoadingPredict, setIsLoadingPredict] = useState(false)

  const { data: isBlockedEntity } = useIsBlockEntity({
    freight_id: isEdit ? freight?.id : undefined,
  })

  const isFieldEditable = (field: string) => editableFields.includes(field)

  useEffect(() => {
    getVehicleCategories().then(categories => setVehicleCategories(categories))
    getVehicleBodies().then(bodies => setVehicleBodies(bodies))

    async function loadData() {
      const usersOperator = await getUserOperation()
      setUsersOperation(usersOperator)
      const usersSeller = await getUserSales()
      setUsersSales(usersSeller)
    }
    loadData()
  }, [])

  useEffect(() => {
    const loadClient = async () => {
      if (initialData?.client_id?.client_business_group_id) {
        dispatch(setClientBusinessGroupId(initialData.client_id.client_business_group_id))
      } else if (initialData?.client_id?.value) {
        const client = await fetchClient(initialData.client_id.value.toString())
        if (client) dispatch(setClientBusinessGroupId(client.client_business_group_id))
      } else if (initialData?.client_contact?.client_business_group_id) {
        dispatch(setClientBusinessGroupId(initialData.client_contact.client_business_group_id))
      }
    }
    loadClient()
  }, [
    dispatch,
    initialData?.client_id?.client_business_group_id,
    initialData?.client_contact?.client_business_group_id,
    initialData?.client_id?.value,
  ])

  return (
    <>
      <Box>
        <Switch
          hidden
          name="isQuotation"
          isChecked={isQuotation}
          initialValue={isQuotation}
          setValue={setValue}
        />

        <Heading size="md" fontWeight="normal" mb="6">
          Informações básicas
        </Heading>

        <Grid templateColumns="repeat(12, 1fr)" gap="4">
          <GridItem colSpan={[12, 4, 3]}>
            <Select
              isDisabled={
                isBlockedEntity && !(usersFreightEditors.includes(user?.email) && isFieldEditable('type'))
              }
              name="type"
              label="Tipo de frete"
              error={errors.type}
              setValue={setValue}
              options={[
                { label: 'Terrestre', value: 'terrestrial' },
                { label: 'Aéreo', value: 'aerial' },
                { label: 'Hotline', value: 'hotline' },
              ]}
              initialValue={initialData?.type || 'terrestrial'}
              onChange={e => {
                setInputToPredict(prevState => ({ ...prevState, type: e.target.value }))
              }}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 4, 3]}>
            <Input
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('received_at'))
              }
              isRequired
              type="datetime-local"
              name="received_at"
              label="Data de solicitação"
              error={errors.received_at}
              setValue={setValue}
              initialValue={initialData?.received_at}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 4, 3]}>
            <Input
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('collect_cargo_at'))
              }
              isRequired
              type="datetime-local"
              name="collect_cargo_at"
              label="Previsão para coleta"
              error={errors.collect_cargo_at}
              setValue={setValue}
              initialValue={initialData?.collect_cargo_at}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 4, 3]}>
            <Input
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('delivered_cargo_at'))
              }
              type="datetime-local"
              name="delivered_cargo_at"
              label="Data de entrega"
              error={errors.delivered_cargo_at}
              setValue={setValue}
              initialValue={initialData?.delivered_cargo_at}
              isRequired
            />
          </GridItem>
          <GridItem colSpan={[12, isAddClientContact ? 9 : 3]}>
            <AutocompleteAsync
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('client_id'))
              }
              isRequired
              name="client_id"
              label="Cliente"
              error={errors.client_id}
              setValue={setValue}
              loadOptions={searchClientsByName}
              onSelectOption={option => {
                dispatch(setClientBusinessGroupId(option.client_business_group_id))
                setIsClearStateClientContact(true)
              }}
              initialValue={initialData?.client_id}
            />
          </GridItem>

          {isLoading || isFetching ? (
            <GridItem colSpan={12}>Carregando solicitantes...</GridItem>
          ) : clienteContacts && clienteContacts.length > 0 ? (
            <>
              {!isAddClientContact && (
                <GridItem colSpan={[12, 6]}>
                  <Autocomplete
                    isDisabled={
                      isBlockedEntity &&
                      !(usersFreightEditors.includes(user?.email) && isFieldEditable('client_contact_id'))
                    }
                    label="Solicitante"
                    name="client_contact.client_contact_id"
                    error={errors.client_contact?.client_contact_id}
                    setValue={setValue}
                    onSelectOption={option => {
                      if (option.value !== '') {
                        setIsAddClientContact(false)
                      }
                    }}
                    clearState={() => {
                      setIsClearStateClientContact(true)
                    }}
                    options={clienteContacts}
                    initialValue={
                      isClearStateClientContact
                        ? undefined
                        : initialData?.client_contact_id?.value
                        ? initialData?.client_contact_id
                        : undefined
                    }
                    isRequired
                  />
                </GridItem>
              )}
            </>
          ) : (
            client_business_group_id &&
            !isAddClientContact && (
              <GridItem colSpan={[12, 6]} mt={8}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Cadastre um solicitante.</AlertTitle>
                </Alert>
              </GridItem>
            )
          )}

          <GridItem colSpan={[12, 6, 3]} mt={10}>
            <Switch
              isDisabled={
                isBlockedEntity &&
                !(
                  usersFreightEditors.includes(user?.email) &&
                  isFieldEditable('client_contact.switch_add_contact')
                )
              }
              name="client_contact.switch_add_contact"
              label="Cadastrar Solicitante"
              error={errors.switch_add_contact}
              isChecked={isAddClientContact}
              initialValue={isAddClientContact}
              setValue={setValue}
              onChange={e => setIsAddClientContact(e.target.checked)}
            />
          </GridItem>
        </Grid>

        {isAddClientContact && (
          <>
            <GridItem colSpan={12}>
              <Divider my="2" />
            </GridItem>

            <GridItem colSpan={12}>
              <Heading size="md" fontWeight="normal" mb="2">
                Dados do solicitante
              </Heading>
            </GridItem>

            <Grid templateColumns="repeat(12, 1fr)" gap="4">
              <ContactByClientForm formState={formState} setValue={setValue} />
            </Grid>

            <GridItem colSpan={12}>
              <Divider my="2" />
            </GridItem>
          </>
        )}
      </Box>
      <Divider my="10" />
      <Box>
        <Heading
          borderLeftColor="primary.500"
          borderLeftWidth="4px"
          pl="4"
          size="md"
          fontWeight="bold"
          mb="6"
        >
          Informações de endereço
        </Heading>
        <Grid templateColumns="repeat(12, 1fr)" gap="4">
          <GridItem colSpan={[6]}>
            <AutocompleteAsync
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('origin_id'))
              }
              isRequired
              name="origin_id"
              label="Origem"
              error={errors.origin_id}
              setValue={setValue}
              loadOptions={searchCitiesByName}
              initialValue={initialData?.origin_id}
              onChange={async e => {
                const cities = await searchCitiesByName(e.target.value)
                const city = cities[0]
                setInputToPredict(prevState => ({
                  ...prevState,
                  origin_lat: city?.lat,
                  origin_lng: city?.lng,
                }))
              }}
            />
          </GridItem>
          <GridItem colSpan={[6]}>
            <AutocompleteAsync
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('destination_id'))
              }
              isRequired
              name="destination_id"
              label="Destino"
              error={errors.destination_id}
              setValue={setValue}
              loadOptions={searchCitiesByName}
              initialValue={initialData?.destination_id}
              onChange={async e => {
                const cities = await searchCitiesByName(e.target.value)
                const city = cities[0]
                setInputToPredict(prevState => ({
                  ...prevState,
                  destination_lat: city?.lat,
                  destination_lng: city?.lng,
                }))
              }}
            />
          </GridItem>
          <GridItem colSpan={[12]}>
            <TextArea
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('required_items'))
              }
              name="required_items"
              label="Itens obrigatórios"
              error={errors.required_items}
              setValue={setValue}
              initialValue={initialData?.required_items}
            />
          </GridItem>
          {!isQuotation && (
            <>
              <GridItem colSpan={[6]}>
                <TextArea
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('address_collect'))
                  }
                  isRequired
                  name="address_collect"
                  label="Possui mais de uma coleta?"
                  error={errors.address_collect}
                  setValue={setValue}
                  initialValue={initialData?.collect_complement}
                  h="120"
                />
              </GridItem>
              <GridItem colSpan={[6]}>
                <TextArea
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('address_delivery'))
                  }
                  isRequired
                  name="address_delivery"
                  label="Possui mais de uma entrega?"
                  error={errors.address_delivery}
                  setValue={setValue}
                  initialValue={initialData?.delivery_complement}
                  h="120"
                />
              </GridItem>
            </>
          )}
          <RadioGroup
            isDisabled={
              isBlockedEntity &&
              !(usersFreightEditors.includes(user?.email) && isFieldEditable('motorist_exists'))
            }
            hidden
            name="motorist_exists"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 1 },
              { label: 'Não', value: 0 },
            ]}
            direction={['column', 'row']}
            initialValue={freight?.motorist_id && isEdit ? 1 : 0}
          />
        </Grid>
      </Box>
      <Divider my="10" />

      <Box>
        <Heading
          borderLeftColor="primary.500"
          borderLeftWidth="4px"
          pl="4"
          size="md"
          fontWeight="bold"
          mb="6"
        >
          Informações sobre a carga
        </Heading>

        <Grid templateColumns="repeat(12, 1fr)" gap="4">
          {vehicleCategories && vehicleBodies && (
            <>
              <GridItem colSpan={[12, 4, 3]}>
                <AutocompleteMulti
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('vehicle_categories'))
                  }
                  isRequired
                  name="vehicle_categories"
                  label="Tipo de veículos"
                  error={errors.vehicle_categories}
                  setValue={setValue}
                  options={vehicleCategories}
                  initialValue={
                    initialData?.vehicle_categories &&
                    initialData?.vehicle_categories.length > 0 &&
                    initialData?.vehicle_categories.every(category => category.label !== 'undefined')
                      ? initialData?.vehicle_categories
                      : undefined
                  }
                />
              </GridItem>
              <GridItem colSpan={[12, 4, 3]}>
                <AutocompleteMulti
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('vehicle_bodies'))
                  }
                  isRequired
                  name="vehicle_bodies"
                  label="Carrocerias"
                  error={errors.vehicle_bodies}
                  setValue={setValue}
                  options={vehicleBodies}
                  initialValue={initialData?.vehicle_bodies}
                />
              </GridItem>
            </>
          )}

          <GridItem colSpan={[12, 4, 3]}>
            <Select
              isDisabled={
                isBlockedEntity &&
                !(
                  usersFreightEditors.includes(user?.email) &&
                  isFieldEditable('cargo.cargo_category_fretebras_id')
                )
              }
              isRequired
              name="cargo.cargo_category_fretebras_id"
              label="Espécie"
              error={errors.cargo?.cargo_category_fretebras_id}
              setValue={setValue}
              options={[{ label: 'Selecione', value: '' }, ...cargoCategoryFretebrasId]}
              initialValue={initialData?.cargo?.cargo_category_fretebras_id}
            />
          </GridItem>

          <GridItem colSpan={[6, 3]}>
            <InputNumberMask
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('cargo.width'))
              }
              name="cargo.weight"
              label="Peso"
              suffix=" Kg"
              thousandSeparator="."
              decimalSeparator=","
              error={errors.cargo?.weight}
              setValue={setValue}
              initialValue={Number(initialData?.cargo?.weight)}
              isRequired={isQuotation}
              onChange={e => {
                setInputToPredict(prevState => ({ ...prevState, weight: e.target.value }))
              }}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 3]}>
            <Input
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('cargo.name'))
              }
              isRequired
              name="cargo.name"
              label="Produto (carga)"
              error={errors.cargo?.name}
              setValue={setValue}
              initialValue={initialData?.cargo?.name}
              uppercaseFirst
            />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <InputNumberMask
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('cargo.value'))
              }
              name="cargo.value"
              label="Valor (NF)"
              isCashBRL
              isRequired
              error={errors.cargo?.value}
              setValue={setValue}
              initialValue={Number(initialData?.cargo?.value)}
              onChange={e => {
                const cargoValue = e.target.value.replace(/\D/g, '')
                setInputToPredict(prevState => ({ ...prevState, value: cargoValue }))
              }}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 3]}>
            <Switch
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('cargo.has_complement'))
              }
              mt={10}
              defaultChecked={false}
              name="cargo.has_complement"
              label="Complemento?"
              error={errors.cargo?.has_complement}
              setValue={setValue}
              initialValue={initialData?.cargo?.has_complement}
              onChange={e =>
                setInputToPredict({
                  ...inputToPredict,
                  has_complement: e?.target?.checked ? 't' : 'f',
                })
              }
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 3]}>
            <Switch
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('cargo.require_tracker'))
              }
              mt={10}
              defaultChecked={false}
              name="cargo.require_tracker"
              label="Exige rastreio?"
              error={errors.cargo?.require_tracker}
              setValue={setValue}
              initialValue={initialData?.cargo?.require_tracker}
              onChange={e =>
                setInputToPredict({
                  ...inputToPredict,
                  require_tracker: e?.target?.checked ? 't' : 'f',
                })
              }
            />
          </GridItem>
        </Grid>
      </Box>
      <Divider my="10" />

      <Box>
        <Heading
          borderLeftColor="primary.500"
          borderLeftWidth="4px"
          pl="4"
          size="md"
          fontWeight="bold"
          mb="6"
        >
          Informações adicionais
        </Heading>

        {servicePricePredictions && (
          <Alert status="info" mb="6" maxW="md">
            <AlertIcon />
            <Tooltip label="Cálculo baseado no histórico de fretes." hasArrow placement="top">
              <AlertTitle>Valor sugerido pela IA</AlertTitle>
            </Tooltip>
            {parseFloat(servicePricePredictions).toFixed(2)}
          </Alert>
        )}

        <Grid templateColumns="repeat(12, 1fr)" gap="4">
          {isEdit && (
            <GridItem colSpan={[12, 4]}>
              <InputNumberMask
                isDisabled={
                  isBlockedEntity &&
                  !(usersFreightEditors.includes(user?.email) && isFieldEditable('agreed_price'))
                }
                name="agreed_price"
                label="Custo do Motorista"
                isCashBRL
                error={errors.agreed_price}
                setValue={setValue}
                initialValue={initialData?.agreed_price ? Number(initialData?.agreed_price) : undefined}
              />
            </GridItem>
          )}

          <GridItem colSpan={[12, 5]}>
            <Flex align="flex-end" justify="row">
              <InputNumberMask
                isDisabled={
                  isBlockedEntity &&
                  !(usersFreightEditors.includes(user?.email) && isFieldEditable('service_price'))
                }
                isRequired={!isQuotation}
                name="service_price"
                label="Valor do Cliente"
                isCashBRL
                error={errors.service_price}
                setValue={setValue}
                initialValue={initialData?.service_price ? Number(initialData?.service_price) : undefined}
                flex="1"
                mr="4"
              />

              <Tooltip
                label="(EXPERIMENTAL) Algorítmo de inteligência artificial para estimar o valor do cliente."
                hasArrow
                placement="top"
              >
                <Button
                  colorScheme="blue"
                  size="sm"
                  p={4}
                  m={3}
                  onClick={async () => {
                    if (!inputToPredict.origin_lat || !inputToPredict.origin_lng) {
                      toast({
                        duration: 5000,
                        isClosable: true,
                        title: 'Erro ao estimar valor',
                        description: 'Selecione uma origem válida para fazer a estimativa do valor do frete.',
                        position: 'top',
                        status: 'error',
                      })
                    } else if (!inputToPredict.destination_lat || !inputToPredict.destination_lng) {
                      toast({
                        duration: 5000,
                        isClosable: true,
                        title: 'Erro ao estimar valor',
                        description: 'Selecione um destino válido para fazer a estimativa do valor do frete.',
                        position: 'top',
                        status: 'error',
                      })
                    } else if (!inputToPredict.value) {
                      toast({
                        duration: 5000,
                        isClosable: true,
                        title: 'Erro ao estimar valor',
                        description: 'Informe o valor da carga para fazer a estimativa do valor do frete.',
                        position: 'top',
                        status: 'error',
                      })
                    } else {
                      const distance = await getFreightDistance({
                        originCity: {
                          lat: Number(inputToPredict.origin_lat),
                          lng: Number(inputToPredict.origin_lng),
                        },
                        destinationCity: {
                          lat: Number(inputToPredict.destination_lat),
                          lng: Number(inputToPredict.destination_lng),
                        },
                      })
                      setIsLoadingPredict(true)
                      const res = await predictServicePrice({
                        ...inputToPredict,
                        distance,
                        weight: inputToPredict.weight?.replace(/\D/g, ''),
                      })
                      if (res.data.prediction) {
                        setServicePricePredictions(res.data.prediction)
                        toast({
                          duration: 5000,
                          isClosable: true,
                          title: 'Análise da IA realizada com sucesso!',
                          description:
                            'A análise da IA foi realizada com sucesso. O valor sugerido foi calculado e está disponível para visualização.',
                          position: 'top',
                          status: 'success',
                        })
                      }
                      setIsLoadingPredict(false)
                    }
                  }}
                  isLoading={isLoadingPredict}
                >
                  <Icon as={GiArtificialIntelligence} fontSize={30} />
                </Button>
              </Tooltip>
            </Flex>
          </GridItem>

          <GridItem colSpan={[12, 6]}>
            <Input
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('description'))
              }
              isRequired
              name="description"
              label="Informações adicionais do embarque"
              error={errors.description}
              setValue={setValue}
              initialValue={initialData?.description}
              uppercaseFirst
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 4]}>
            {usersOperation.length > 0 && (
              <Autocomplete
                isDisabled={
                  isBlockedEntity &&
                  !(usersFreightEditors.includes(user?.email) && isFieldEditable('creator_id'))
                }
                isRequired
                name="creator_id"
                label="Responsável do frete"
                setValue={setValue}
                error={errors.creator_id}
                options={usersOperation}
                initialValue={
                  initialData?.creator_id && String(initialData?.creator_id.label) !== ''
                    ? initialData?.creator_id
                    : undefined
                }
              />
            )}
          </GridItem>

          <GridItem colSpan={[12, 6, 4]}>
            {usersSales.length > 0 && (
              <Autocomplete
                isDisabled={
                  isBlockedEntity &&
                  !(usersFreightEditors.includes(user?.email) && isFieldEditable('seller_id'))
                }
                isRequired
                name="seller_id"
                label="Vendedor do frete"
                setValue={setValue}
                error={errors.seller_id}
                options={usersSales}
                initialValue={
                  initialData?.seller_id && String(initialData?.seller_id.label) !== 'undefined'
                    ? initialData?.seller_id
                    : undefined
                }
              />
            )}
          </GridItem>

          <GridItem colSpan={[12, 12, 4]}>
            <Select
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('transporter'))
              }
              isRequired
              name="transporter"
              label="Transportadora"
              error={errors.transporter}
              setValue={setValue}
              options={[{ label: '', value: '' }, ...conveyers]}
              initialValue={initialData?.transporter}
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <Select
              isDisabled={
                isBlockedEntity && !(usersFreightEditors.includes(user?.email) && isFieldEditable('sector'))
              }
              options={[
                { label: '', value: '' },
                { label: 'Distribuição', value: 'distribution' },
                { label: 'MRO', value: 'mro' },
              ]}
              isRequired
              name="sector"
              label="Setor"
              error={errors.sector}
              setValue={setValue}
              initialValue={initialData?.sector}
            />
          </GridItem>

          <GridItem colSpan={[12, 4]}>
            <Switch
              isDisabled={
                isBlockedEntity &&
                !(usersFreightEditors.includes(user?.email) && isFieldEditable('publish_on_fretebras'))
              }
              mt={10}
              name="publish_on_fretebras"
              label="Publicar no fretebras?"
              error={errors.publish_on_fretebras}
              setValue={setValue}
              initialValue={initialData?.publish_on_fretebras}
              onChange={e => setPublishOnFretebras(e.target.checked)}
            />
          </GridItem>

          {!isQuotation && (
            <>
              <GridItem colSpan={[12, 2]}>
                <Select
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('client_ref_type'))
                  }
                  name="client_ref_type"
                  label="Tipo ref cliente"
                  error={errors.client_ref_type}
                  setValue={setValue}
                  options={[
                    { label: 'Selecione', value: '' },
                    { label: 'CC', value: 'cc' },
                    { label: 'DT', value: 'dt' },
                    { label: 'NC', value: 'nc' },
                    { label: 'NP', value: 'np' },
                    { label: 'PC', value: 'pc' },
                    { label: 'PO', value: 'po' },
                    { label: 'RC', value: 'rc' },
                    { label: 'SC', value: 'sc' },
                    { label: 'SOTE', value: 'sote' },
                    { label: 'STE', value: 'ste' },
                    { label: 'ID', value: 'id' },
                  ]}
                  initialValue={initialData?.client_ref_type}
                />
              </GridItem>

              <GridItem colSpan={[12, 3]}>
                <Input
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('client_ref_number'))
                  }
                  name="client_ref_number"
                  label="Nº ref cliente"
                  error={errors.client_ref_number}
                  setValue={setValue}
                  initialValue={initialData?.client_ref_number}
                />
              </GridItem>
            </>
          )}
        </Grid>

        {publishOnFretebras && (
          <Box borderWidth="1px" p="4" rounded="lg" borderColor="blackAlpha.300" bg="blackAlpha.50" mt={6}>
            <Heading
              borderLeftColor="primary.500"
              borderLeftWidth="4px"
              pl="4"
              size="md"
              fontWeight="bold"
              mb="6"
            >
              Informações fretebras
            </Heading>

            <Grid templateColumns="repeat(12, 1fr)" gap="4">
              <GridItem colSpan={[12, 4]}>
                <Select
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('link_motorist_at'))
                  }
                  isRequired
                  name="suggested_price_type"
                  label="Tipo de preço"
                  error={errors.suggested_price_type}
                  setValue={setValue}
                  options={[
                    { label: 'Selecione', value: '' },
                    { label: 'Cheio', value: 'full' },
                    { label: 'Por tonelada', value: 'per_ton' },
                    { label: 'Por quilômetro', value: 'per_kilometer' },
                    { label: 'A combinar', value: 'to_match' },
                  ]}
                  initialValue={
                    initialData?.suggested_price_type ? initialData?.suggested_price_type : suggestedPriceType
                  }
                  onSelectOption={option => {
                    setSuggestedPriceType(option.value)
                  }}
                />
              </GridItem>

              {suggestedPriceType && suggestedPriceType !== 'to_match' && (
                <GridItem colSpan={[12, 4]}>
                  <InputNumberMask
                    isDisabled={
                      isBlockedEntity &&
                      !(usersFreightEditors.includes(user?.email) && isFieldEditable('suggested_price'))
                    }
                    isRequired
                    name="suggested_price"
                    label="Valor a ofertar"
                    isCashBRL
                    error={errors.suggested_price}
                    setValue={setValue}
                    initialValue={Number(initialData?.suggested_price)}
                  />
                </GridItem>
              )}

              <GridItem colSpan={[6, 1]}>
                <Switch
                  isDisabled={
                    isBlockedEntity &&
                    !(usersFreightEditors.includes(user?.email) && isFieldEditable('has_toll'))
                  }
                  mt={10}
                  setValue={setValue}
                  name="has_toll"
                  label="Pedágio?"
                  error={errors.has_toll}
                  initialValue={initialData?.has_toll ? initialData?.has_toll : false}
                />
              </GridItem>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  )
}
