import { GridItem } from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import * as yup from 'yup'
import { Select } from '../../components/form'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { rolesRequesters } from '../../services/endpoints/clients/client-contacts/postContactFromClient'
import { ClientContactType } from '../../services/types/ClientType'

interface ContactByClientFormProps {
  contactData?: Partial<ClientContactType>
  formState: FormState<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

export function ContactByClientForm({
  formState,
  setValue,
  contactData,
}: ContactByClientFormProps): JSX.Element {
  const { errors } = formState

  return (
    <>
      <GridItem colSpan={[12, 6]}>
        <Input
          isRequired
          name="client_contact.name"
          label="Nome"
          error={errors.client_contact?.name}
          setValue={setValue}
          initialValue={contactData?.name}
        />
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <InputMask
          isRequired
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          name="client_contact.phone"
          label="Telefone"
          error={errors.client_contact?.phone}
          setValue={setValue}
          initialValue={contactData?.phone}
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <InputMask
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          name="client_contact.phone2"
          label="Telefone 2"
          error={errors.client_contact?.phone2}
          setValue={setValue}
          initialValue={contactData?.phone2}
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Input
          isRequired
          name="client_contact.email"
          type="email"
          label="E-mail"
          error={errors.client_contact?.email}
          setValue={setValue}
          initialValue={contactData?.email}
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Select
          isRequired
          name="client_contact.role"
          label="Cargo"
          error={errors.client_contact?.role}
          setValue={setValue}
          options={rolesRequesters}
          initialValue={contactData?.role}
        />
      </GridItem>
    </>
  )
}
export const ContactByClientFormSchema = yup.object().shape({
  client_contact: yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    phone: yup.string().required('Campo obrigatório'),
    phone2: yup.string(),
    email: yup.string().required('Campo obrigatório'),
    role: yup.string().required('Campo obrigatório'),
    client_business_group_id: yup.object().required('Campo obrigatório'),
  }),
})
