/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiPaperclip } from 'react-icons/fi'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { RiEditLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { PreviewFiles } from '../../../../../components/DropzoneImageUpload/PreviewFiles'
import { toastError } from '../../../../../config/error/toastError'
import { useAuth } from '../../../../../contexts/AuthContext'
import { Layout } from '../../../../../layout'
import { useGetBalanceRequest } from '../../../../../services/endpoints/freights/getBalanceRequest'
import { getStatus } from '../../../../../services/endpoints/freights/getFreights'
import {
  BalanceRequestAttachmentTypeEnum,
  BalanceRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'
import { balanceRequestStatusLegend } from '../../../../../services/utils/balanceRequestStatusLegend'
import { formatAccountType } from '../../../../../services/utils/formatAccountType'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'
import { getBalanceRequestColorByStatus } from '../../../../../services/utils/getBalanceRequestColorByStatus'
import { HandleBalanceRequestStatusModal } from '../modals/HandleBalanceRequestStatusModal'
import { UpdateBalanceRequestModal } from '../modals/UpdateBalanceRequestModal'
import { UploadBalanceRequestContractModal } from '../modals/UploadBalanceRequestContractModal'
import { UploadBalanceRequestReceiptsModal } from '../modals/UploadBalanceRequestReceiptsModal'

type RequestParams = {
  id: string
}

type AnalysisStatusType = 'approved' | 'rejected' | 'request_review' | 'finish'

type SplitAttachmentsType = {
  contractAttachments: string[]
  receiptAttachments: string[]
}

export function ShowBalanceRequest(): JSX.Element {
  const [splitAttachments, setSplitAttachments] = useState<SplitAttachmentsType>()
  const toast = useToast()
  const { id } = useParams<RequestParams>()
  const bg = useColorModeValue('white', 'gray.800')
  const tabs = ['Contratos:', 'Recibos:']
  const { user } = useAuth()

  const [analysisStatus, setAnalysisStatus] = useState<AnalysisStatusType>('approved')

  const {
    isOpen: isOpenHandleBalanceRequestStatusModal,
    onOpen: onOpenHandleBalanceRequestStatusModal,
    onClose: onCloseHandleBalanceRequestStatusModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUploadBalanceRequestContractModal,
    onOpen: onOpenUploadBalanceRequestContractModal,
    onClose: onCloseUploadBalanceRequestContractModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUploadBalanceRequestReceiptsModal,
    onOpen: onOpenUploadBalanceRequestReceiptsModal,
    onClose: onCloseUploadBalanceRequestReceiptsModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateBalanceRequestModal,
    onOpen: onOpenUpdateBalanceRequestModal,
    onClose: onCloseUpdateBalanceRequestModal,
  } = useDisclosure()

  const { data: balanceRequestData, isLoading, isError, error } = useGetBalanceRequest({ id })

  function handleStatus(status: AnalysisStatusType): void {
    setAnalysisStatus(status)
    onOpenHandleBalanceRequestStatusModal()
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  useEffect(() => {
    if (balanceRequestData && balanceRequestData.attachments && balanceRequestData.attachments.length > 0) {
      const contractAttachments = balanceRequestData.attachments
        .filter(
          attachment =>
            attachment.type === BalanceRequestAttachmentTypeEnum.CONTRACT &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      const receiptAttachments = balanceRequestData.attachments
        .filter(
          attachment =>
            attachment.type === BalanceRequestAttachmentTypeEnum.RECEIPT &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      setSplitAttachments({ contractAttachments, receiptAttachments })
    }
  }, [balanceRequestData])

  return (
    <Layout>
      {!isLoading ? (
        balanceRequestData ? (
          <>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
              <Heading fontSize="2xl" display="flex" alignItems="center">
                <Text ml={2}>Frete #{balanceRequestData?.freight?.freight_number} - </Text>

                <Tooltip
                  label={balanceRequestStatusLegend(balanceRequestData?.status)}
                  hasArrow
                  placement="top"
                  arrowSize={15}
                >
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme={getBalanceRequestColorByStatus(balanceRequestData.status)}
                    ml={2}
                  >
                    {balanceRequestData.formatted_status}
                  </Badge>
                </Tooltip>
              </Heading>

              {balanceRequestData.status === BalanceRequestStatusEnum.AWAITING_ANALYSIS &&
                user.email === 'fernanda.salino@spotx.com.br' && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => {
                        handleStatus('approved')
                      }}
                    >
                      <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                    </Button>

                    <Button
                      size="sm"
                      colorScheme="red"
                      ml={2}
                      onClick={() => {
                        handleStatus('rejected')
                      }}
                    >
                      <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                    </Button>
                  </Box>
                )}

              {balanceRequestData.status === BalanceRequestStatusEnum.AWAITING_CONTRACT && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      onOpenUploadBalanceRequestContractModal()
                    }}
                  >
                    <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar contrato
                  </Button>
                </Box>
              )}

              {balanceRequestData.status === BalanceRequestStatusEnum.AWAITING_PAYMENT &&
                user.email === 'jessica.silva@spotx.com.br' && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="yellow"
                      borderRadius="full"
                      onClick={onOpenUploadBalanceRequestReceiptsModal}
                    >
                      <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar comprovante
                    </Button>

                    <Button
                      size="sm"
                      colorScheme="cyan"
                      borderRadius="full"
                      onClick={() => {
                        handleStatus('request_review')
                      }}
                      ml={2}
                    >
                      <Icon fontSize={16} as={RiEditLine} mr={1} /> Reprovar
                    </Button>
                  </Box>
                )}

              {balanceRequestData.status === BalanceRequestStatusEnum.PAID && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      handleStatus('finish')
                    }}
                  >
                    <Icon fontSize={16} as={IoCheckmark} mr={1} /> Finalizar
                  </Button>
                </Box>
              )}

              {balanceRequestData.status === BalanceRequestStatusEnum.REJECTED && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      onOpenUpdateBalanceRequestModal()
                    }}
                  >
                    <Icon fontSize={16} as={RiEditLine} mr={1} /> Editar
                  </Button>
                </Box>
              )}
            </Flex>

            {balanceRequestData.reject_reason && (
              <Alert status="warning" variant="left-accent">
                <Box>
                  <AlertTitle mb={2}>Motivo de reprovação!</AlertTitle>
                  <AlertDescription>{balanceRequestData.reject_reason}</AlertDescription>
                </Box>
              </Alert>
            )}

            {balanceRequestData.observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação!</AlertTitle>
                  <AlertDescription>{balanceRequestData.observation}</AlertDescription>
                </Box>
              </Alert>
            )}

            {balanceRequestData.payment_observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação de pagamento!</AlertTitle>
                  <AlertDescription>{balanceRequestData.payment_observation}</AlertDescription>
                </Box>
              </Alert>
            )}

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações solicitação de saldo
                <Tooltip label="Responsável - Data de criação" hasArrow placement="top" arrowSize={10}>
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme="gray"
                    ml={2}
                    display="flex"
                    alignItems="center"
                  >
                    {balanceRequestData.creator?.name} - {balanceRequestData.created_at}
                  </Badge>
                </Tooltip>
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Valor do custo total:</Text>
                  <Text fontWeight="medium" ml="1">
                    {balanceRequestData?.total_value_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">CTe: </Text>
                  <Text fontWeight="medium" ml="1">
                    {balanceRequestData?.cte ? balanceRequestData?.cte?.nct : 'Não informado'}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações gerais do frete
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Número do frete:</Text>
                  <Tooltip label="Ir para página do frete" hasArrow>
                    <Text
                      as={Link}
                      to={`/freights/show/${balanceRequestData?.freight_id}`}
                      fontWeight="medium"
                      ml="1"
                    >
                      #{balanceRequestData?.freight?.freight_number}
                    </Text>
                  </Tooltip>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Status do frete:</Text>
                  <Badge px="2" py="1" colorScheme="gray" ml={2}>
                    {getStatus(balanceRequestData?.freight?.status)}
                  </Badge>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente:</Text>
                  <Text fontWeight="medium" ml="1">
                    {balanceRequestData?.freight?.client?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente CNPJ:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(balanceRequestData?.freight?.client?.cnpj)}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Motorista: </Text>
                  <Text fontWeight="medium" ml="1">
                    {balanceRequestData.freight?.motorist?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Nota Motorista: </Text>
                  <Text fontWeight="medium" ml="1">
                    {balanceRequestData.freight?.motorist?.rate_score}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações de pagamento
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">E-Frete:</Text>
                  <Text fontWeight="medium" ml="1">
                    {balanceRequestData?.e_frete ? 'Sim' : 'Não'}
                  </Text>
                </WrapItem>

                {balanceRequestData.pix && (
                  <WrapItem>
                    <Text color="gray.400">Pix:</Text>
                    <Text fontWeight="medium" ml="1">
                      {balanceRequestData?.pix}
                    </Text>
                  </WrapItem>
                )}

                {balanceRequestData.account_type && (
                  <WrapItem>
                    <Text color="gray.400">Tipo de conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {formatAccountType(balanceRequestData?.account_type)}
                    </Text>
                  </WrapItem>
                )}

                {balanceRequestData.bank && (
                  <WrapItem>
                    <Text color="gray.400">Banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {balanceRequestData?.bank}
                    </Text>
                  </WrapItem>
                )}

                {balanceRequestData.agency && (
                  <WrapItem>
                    <Text color="gray.400">Agencia:</Text>
                    <Text fontWeight="medium" ml="1">
                      {balanceRequestData?.agency}
                    </Text>
                  </WrapItem>
                )}

                {balanceRequestData.account && (
                  <WrapItem>
                    <Text color="gray.400">Conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {balanceRequestData?.account}
                    </Text>
                  </WrapItem>
                )}

                {balanceRequestData.account_digit && (
                  <WrapItem>
                    <Text color="gray.400">Código banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {balanceRequestData?.account_digit}
                    </Text>
                  </WrapItem>
                )}
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Arquivos Vinculados
              </Heading>

              <Tabs colorScheme="orange" w="full">
                <TabList w="full" overflowX="auto" overflowY="hidden">
                  {tabs.map(tab => (
                    <Tab
                      fontSize="sm"
                      key={tab}
                      _active={{ outline: 'none' }}
                      _focus={{ outline: 'none' }}
                      _hover={{ outline: 'none', bg: 'none' }}
                      whiteSpace="nowrap"
                    >
                      {tab}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {tabs.map(tab => (
                    <TabPanel key={tab} p="0" mt="4">
                      {tab === 'Contratos:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.contractAttachments &&
                          splitAttachments.contractAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.contractAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum contrato de frete encontrado</Text>
                          )}
                        </>
                      )}
                      {tab === 'Recibos:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.receiptAttachments &&
                          splitAttachments.receiptAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.receiptAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum recibo encontrado</Text>
                          )}
                        </>
                      )}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar a solicitação de saldo</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de saldo encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}

      {isOpenHandleBalanceRequestStatusModal && (
        <HandleBalanceRequestStatusModal
          isOpen={isOpenHandleBalanceRequestStatusModal}
          onClose={onCloseHandleBalanceRequestStatusModal}
          analysisStatus={analysisStatus}
        />
      )}

      {isOpenUploadBalanceRequestContractModal && (
        <UploadBalanceRequestContractModal
          isOpen={isOpenUploadBalanceRequestContractModal}
          onClose={onCloseUploadBalanceRequestContractModal}
        />
      )}

      {isOpenUploadBalanceRequestReceiptsModal && balanceRequestData && (
        <UploadBalanceRequestReceiptsModal
          isOpen={isOpenUploadBalanceRequestReceiptsModal}
          onClose={onCloseUploadBalanceRequestReceiptsModal}
          e_frete={balanceRequestData.e_frete}
        />
      )}

      {isOpenUpdateBalanceRequestModal && balanceRequestData && (
        <UpdateBalanceRequestModal
          isOpen={isOpenUpdateBalanceRequestModal}
          onClose={onCloseUpdateBalanceRequestModal}
          initial_value={balanceRequestData}
        />
      )}
    </Layout>
  )
}
