import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { RiAddLine, RiArrowDownLine, RiArrowUpLine, RiEditLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetClient } from '../../../../../services/endpoints/clients/getClient'
import { UpdateClientModal } from '../../../edit/UpdateClientModal'
import { useGlobalVariables } from '../../../../../contexts/GlobalVariables'
import { toastError } from '../../../../../config/error/toastError'

interface ClientInfoProps {
  client_id: string
  bg: string
}

export function ClientInfo({ client_id, bg }: ClientInfoProps): JSX.Element {
  const toast = useToast()
  const { setPageTitle } = useGlobalVariables()
  const {
    data: client,
    isLoading,
    isFetching,
    refetch,
    isError: isErrorGetClient,
    error: errorGetClient,
  } = useGetClient(client_id)
  setPageTitle(`${client?.name} | Clientes | SpotX Admin`)
  const [clientIdToUpdate, setClientIdToUpdate] = useState<string>()
  const { isOpen: isCollapseInfoOpen, onToggle: onCollapseInfoToggle } = useDisclosure()

  useEffect(() => {
    if (isErrorGetClient) {
      toastError({ toast, error: errorGetClient })
    }
  }, [isErrorGetClient, errorGetClient, toast])

  const {
    onClose: onCloseUpdateClient,
    isOpen: isOpenUpdateClient,
    onOpen: onOpenUpdateClient,
  } = useDisclosure()

  return isLoading ? (
    <Flex justify="center">
      <Spinner />
    </Flex>
  ) : !client ? (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Erro ao carregar os dados do cliente!</AlertTitle>
      <AlertDescription>
        Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o administrador do
        sistema.
      </AlertDescription>
    </Alert>
  ) : (
    <Box bg={bg} p="6" borderRadius="8" shadow="md">
      <Flex mb="6" justify="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Informações do cliente
          {!isLoading && isFetching && <Spinner size="sm" ml="4" />}
        </Heading>

        <Stack direction={['column', 'row']}>
          <Button
            as={Link}
            to="/clients/create"
            size="sm"
            colorScheme="blue"
            leftIcon={<Icon as={RiAddLine} fontSize="18" />}
          >
            Novo
          </Button>

          <Button
            size="sm"
            colorScheme="orange"
            leftIcon={<Icon as={RiEditLine} />}
            onClick={() => {
              setClientIdToUpdate(client.id)
              onOpenUpdateClient()
            }}
          >
            Editar
          </Button>
        </Stack>
      </Flex>
      <Divider my="6" />

      <Box>
        <Heading size="md" fontWeight="normal" mb="6">
          Dados pessoais
        </Heading>
        <SimpleGrid minChildWidth="300px" spacing="4">
          <WrapItem>
            <Text color="gray.400">Razão social:</Text>
            <Text fontWeight="medium" ml="1">
              {client.name}
            </Text>
          </WrapItem>
          <WrapItem>
            <Text color="gray.400">Apelido:</Text>
            <Text fontWeight="medium" ml="1">
              {client.nickname}
            </Text>
          </WrapItem>
          <WrapItem>
            <Text color="gray.400">CNPJ:</Text>
            <Text fontWeight="medium" ml="1">
              {client.cnpj}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Email:</Text>
            <Text fontWeight="medium" ml="1">
              {client.email}
            </Text>
          </WrapItem>
          <WrapItem>
            <Text color="gray.400">Telefone:</Text>
            <Text fontWeight="medium" ml="1">
              {client.phone}
            </Text>
          </WrapItem>
          <WrapItem>
            <Text color="gray.400">Atividade principal:</Text>
            <Text fontWeight="medium" ml="1">
              {client.main_activity}
            </Text>
          </WrapItem>
        </SimpleGrid>
      </Box>

      <Collapse in={isCollapseInfoOpen} animateOpacity>
        <Box>
          <Divider my="6" />
          <Heading size="md" fontWeight="normal" mb="6">
            Endereço
          </Heading>
          <SimpleGrid minChildWidth="300px" spacing="6" mb="6">
            <WrapItem>
              <Text color="gray.400">CEP:</Text>
              <Text fontWeight="medium" ml="1">
                {client.zipcode}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">Endereço:</Text>
              <Text fontWeight="medium" ml="1">
                {client.address}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">Número:</Text>
              <Text fontWeight="medium" ml="1">
                {client.address_number}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">Bairro:</Text>
              <Text fontWeight="medium" ml="1">
                {client.district}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">Cidade:</Text>
              <Text fontWeight="medium" ml="1">
                {client.city}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">UF:</Text>
              <Text fontWeight="medium" ml="1">
                {client.state}
              </Text>
            </WrapItem>
          </SimpleGrid>
          <Divider mb="6" />
        </Box>
      </Collapse>

      <Collapse in={isCollapseInfoOpen} animateOpacity>
        <Box>
          <Heading size="md" fontWeight="normal" mb="6">
            Grupo empresarial
          </Heading>
          <SimpleGrid minChildWidth="300px" spacing="6" mb="6">
            <WrapItem>
              <Text color="gray.400">Nome: </Text>
              <Text fontWeight="medium" ml="1">
                {client.clientBusinessGroup?.name}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">Segmento:</Text>
              <Text fontWeight="medium" ml="1">
                {client.clientBusinessGroup?.segment?.name}
              </Text>
            </WrapItem>
          </SimpleGrid>
          <Divider mb="6" />
        </Box>
      </Collapse>

      <Flex justify="center" my="4">
        <Button
          variant="ghost"
          size="sm"
          rightIcon={isCollapseInfoOpen ? <Icon as={RiArrowUpLine} /> : <Icon as={RiArrowDownLine} />}
          onClick={onCollapseInfoToggle}
        >
          Mostrar {isCollapseInfoOpen ? 'menos' : 'mais'}
        </Button>
      </Flex>
      {clientIdToUpdate && (
        <UpdateClientModal
          onClose={() => {
            onCloseUpdateClient()
            refetch()
          }}
          isOpen={isOpenUpdateClient}
          client_id={clientIdToUpdate}
        />
      )}
    </Box>
  )
}
