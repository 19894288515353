import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { publicApiServer } from '../../../publicApi'
import { ResponseListApiType } from '../../../types'
import { ClientMonthlyNps } from '../../../types/ClientType'

export type GetClientMonthlyNpsResponse = {
  total: number
  data: ClientMonthlyNps[]
}

export interface FilterClientMonthlyNpsData {
  page?: number
  per_page?: number
  initial_date?: string
  final_date?: string
  client_id?: string
  unpaged?: boolean
}

export async function fetchAllClientMonthlyNps(
  params: FilterClientMonthlyNpsData,
): Promise<GetClientMonthlyNpsResponse> {
  const { data: res } = await publicApiServer.get<ResponseListApiType<ClientMonthlyNps[]>>(
    '/list-client-monthly-nps',
    {
      params,
    },
  )
  const { total } = res.data
  const { data } = res.data
  const feedbacks = data.map(feedback => {
    return {
      ...feedback,
      created_at: format(new Date(feedback.created_at), 'dd/MM/yy'),
    }
  })

  return { total, data: feedbacks }
}

export function useGetAllClientMonthlyNps(
  params: FilterClientMonthlyNpsData,
): UseQueryResult<GetClientMonthlyNpsResponse> {
  return useQuery(['list-client-monthly-nps', params], () => fetchAllClientMonthlyNps(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
