import React from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Select } from '../../../../../components/form'

const truckOptions = [
  { value: '2', label: 'Caminhão leve, ônibus, caminhão-trator e furgão (2 eixos, rodagem dupla)' },
  { value: '3', label: 'Automóvel e caminhonete com semirreboque (3 eixos, rodagem simples)' },
  { value: '4', label: 'Caminhão, caminhão-trator com semirreboque e ônibus (3 eixos, rodagem dupla)' },
  { value: '5', label: 'Automóvel e caminhonete com reboque (4 eixos, rodagem simples)' },
  { value: '6', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (4 eixos, rodagem dupla)' },
  { value: '7', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (5 eixos, rodagem dupla)' },
  { value: '8', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (6 eixos, rodagem dupla)' },
  { value: '9', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (7 eixos, rodagem dupla)' },
  { value: '10', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (8 eixos, rodagem dupla)' },
]
interface TruckTypeSelectProps {
  value?: number
  setValue: any
  setTruckCategory: (event: React.ChangeEvent<HTMLSelectElement>) => void
  errors?: any
}

export const TruckTypeSelect = ({
  value,
  setTruckCategory,
  setValue,
  errors,
}: TruckTypeSelectProps): JSX.Element => {
  return (
    <FormControl>
      <FormLabel>Selecione o tipo de caminhão</FormLabel>
      <Select
        placeholder="Selecione o tipo de caminhão"
        initialValue={value?.toString()}
        onChange={e => setTruckCategory(e)}
        setValue={setValue}
        name="truckCategory"
        options={truckOptions}
        error={errors}
      />
      {errors?.truckType && <span>{errors.truckType}</span>}
    </FormControl>
  )
}
