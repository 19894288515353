import { Box, Button, Divider, Flex, Heading, HStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { Layout } from '../../../layout'
import { CreateClientFormData, usePostClient } from '../../../services/endpoints/clients/postClient'
import { validateClientBusinessGroupOptions } from '../client-business-group/utils/validate-client-business-group-options'
import { ClientForm, ClientFormSchema } from '../ClientForm'

export function CreateClient(): JSX.Element {
  const history = useHistory()
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(ClientFormSchema),
  })
  const { isSubmitting } = formState
  const createClient = usePostClient({
    onSuccess: () => {
      queryClient.invalidateQueries('clients')
    },
  })

  const handleCreateClient: SubmitHandler<CreateClientFormData> = async data => {
    const { clientBusinessGroup, ...clientData } = data
    const name = clientBusinessGroup?.name

    try {
      if (name && name.label && !name.value) {
        const alreadyExistsGroup = await validateClientBusinessGroupOptions(name.label)

        if (alreadyExistsGroup) {
          toastError({ toast, error: alreadyExistsGroup })
          return
        }

        Object.assign(clientData, {
          clientBusinessGroup: {
            name: name.label,
          },
        })
      }

      if (name && name.label && name.value) {
        Object.assign(clientData, {
          client_business_group_id: name.value,
        })
      }
      await createClient.mutateAsync(clientData)

      history.push('/clients')

      toast({
        title: 'Cliente cadastrado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout hasBackground>
      <Flex alignItems="center" justify="space-between" mb="4">
        <Heading size="lg" fontWeight="normal">
          Cadastrar cliente
        </Heading>
      </Flex>
      <Divider my="4" />

      <Box as="form" onSubmit={handleSubmit(handleCreateClient)} noValidate>
        <ClientForm formState={formState} setValue={setValue} />
        <Flex mt="8" justify="flex-end">
          <HStack spacing="4">
            <Link to="/clients">
              <Button>Cancelar</Button>
            </Link>
            <Button type="submit" colorScheme="orange" isLoading={isSubmitting}>
              Salvar
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Layout>
  )
}
