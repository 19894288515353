import { Box, Table, Tbody, Td, Th, Thead, Tr, Heading } from '@chakra-ui/react'
import { useState } from 'react'
import { Pagination } from '../../../../../layout/Pagination'
import { useGetAllClientMonthlyNps } from '../../../../../services/endpoints/clients/client-monthly-nps/getAllClientMonthlyNps'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'

export interface ContactByClientProps {
  client_id: string
  bg: string
}

export function HistoryClientMonthlyNps({ client_id, bg }: ContactByClientProps): JSX.Element {
  const [page, setPage] = useState(1)

  const { data: clientMonthlyNps } = useGetAllClientMonthlyNps({
    client_id,
    page,
  })

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Heading size="lg" fontWeight="normal">
        Histórico de feedbacks
      </Heading>

      <Table d={displayAdaptable} overflowX="auto">
        <Thead>
          <Tr>
            <Th>Data</Th>
            <Th>Nota</Th>
            <Th>Motivo</Th>
            <Th>Solicitante</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clientMonthlyNps?.data?.map(feedback => (
            <Tr key={feedback.id}>
              <Td>{feedback.created_at}</Td>
              <Td>{feedback.recommendation_score}</Td>
              <Td>{feedback.reason}</Td>
              <Td>{feedback.client_contact?.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {clientMonthlyNps && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={clientMonthlyNps.total}
          registersPerPage={10}
          onPageChange={setPage}
          p="6"
        />
      )}
    </Box>
  )
}
